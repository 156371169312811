import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import Amplify from "aws-amplify";
import store from "./store";
Vue.config.productionTip = false;
Amplify.configure({
  Auth: {
    userPoolId: "ap-southeast-1_BYa8prH0i",
    region: "ap-southeast-1",
    userPoolWebClientId: "4hf7a3uofdkep9hqtfe6k5fl33",
  },
  API: {
    aws_project_region: "ap-southeast-1",
    aws_appsync_graphqlEndpoint: "https://wkytj6ocrjco3cd75lqfuykhm4.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-southeast-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "Report_issue",
        endpoint: " https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
      },
    ],
  },
});
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
