import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/MainHomePages/LoginPage.vue";
import ReportIssue from "@/views/ChildHomePages/ReportIssue.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/ReportIssue",
    name: "ReportIssue",
    component: ReportIssue,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () => import(/* webpackChunkName: "LandingPage" */ "../views/MainHomePages/LandingPage.vue"),
    children: [
      {
        path: "/Projects",
        name: "Projects",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/Projects.vue"),
      },
      {
        path: "/Users",
        name: "Users",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/Users.vue"),
      },
      {
        path: "/ListOfBMSUsers",
        name: "BMSUsers",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/ListOfBMSUsers.vue"),
      },
      {
        path: "/DashBoard",
        name: "DashBoard",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/DashBoard.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
